import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import Seo from "../components/SEO"

const ProjectsPage = ({ data }) => {
  const {
    allMdx: { nodes: projects },
  } = data

  return (
    <Layout>
      <Seo title="Projects" description="A collections of my projects" />
      <Projects projects={projects} title="all projects" />
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: { fields: frontmatter___rate }
    ) {
      nodes {
        id
        slug
        frontmatter {
          rate
          title
          desc
          github
          projectSite
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
